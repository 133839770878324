<template>
  <div class="lexicalGrammar">
    <div class="cont">
      <div class="title">
        {{ grammar.QuestionTitle }}
      </div>
      <el-radio-group v-model="value" @change="handChange()">
        <p v-for="(b, i) in grammar.BankItem" :key="i">
          <el-radio :label="b.ItemNum">{{b.ItemNum}}.{{ b.ItemTitle }}</el-radio>
        </p>
      </el-radio-group>
      <div v-if="show">
        <p :class="value === grammar.AnswerStr ? 'hdzq' : 'hdcw'">
          {{ value === grammar.AnswerStr ? "回答正确" : "回答错误" }}
        </p>
        <p class="analysis">
          <span class="fw-bold">【答案】</span>
          <span :class="value === grammar.AnswerStr ? 'hdzq' : 'hdcw'">
            {{ grammar.AnswerStr }}
          </span>
        </p>
        <p class="answer">
          <span class="fw-bold">【解析】</span>
          <span>{{ grammar.QuestionAnalysis }} </span>
        </p>
      </div>
    </div>
    <div class="btn_que" v-if="!show">
      <div class="act" @click="handleSubmit">提交</div>
    </div>
    <div class="btn_que" v-if="show">
      <!-- <div class="act">上一个</div> -->
      <div @click="next">下一个</div>
    </div>
    <div class="btn_que">
      <div @click="goHistory">返回上一页</div>
    </div>
  </div>
</template>

<script>
import {
  queryGrammar,
  qrammarSave,
  queryGrammarRecord
} from "@/api/questionBank";
export default {
  components: {},
  computed: {},
  watch: {},
  data () {
    return {
      value: "",
      pageIndex: 1,
      pageSize: 1,
      route: {},
      grammar: {},
      show: false,
      history: false,
    };
  },
  mounted () {
    // exercises 0 习题中心 1练习记录
    this.route = this.$route.query
    this.init()
  },
  methods: {
    goHistory () {
      this.$router.go(-1)
    },
    next () {
      this.init()
      this.value = ""
      this.show = false
    },
    async handleSubmit () {
      if (!this.value) {
        this.$message.warning("请选择");
        return false
      }
      let parm = {
        studentId: this.route.UserID,
        courseId: this.route.courseId,
        questionId: this.grammar.QuestionId,
        studentAnswer: this.value
      }
      const res = await qrammarSave(parm);
      if (res.success == true) {
        this.show = true
      }
    },
    async init () {
      let parm = "?studentId=" + this.route.UserID +
        "&courseId=" + this.route.courseId +
        "&pageIndex=" + this.pageIndex +
        "&pageSize=" + this.pageSize
      const res = this.route.exercises == 0 ?
        await queryGrammar(parm) :
        await queryGrammarRecord(parm)
      if (res.success == true) {
        if (this.route.exercises != 0) {
          this.show = true
        } else {
          this.show = false
        }
        if (res.response.data.length <= 0) {
          this.$message.warning("当前暂无更多答题");
          this.history = true
        }
        this.grammar = res.response.data[0]
      }
    },
    handChange () {
    }
  }
};
</script>
<style lang="less" scoped>
.lexicalGrammar {
  min-height: 100vh;
  .cont {
    background-color: #fff;
    padding: 0 30px 20px;
    .title {
      line-height: 100%;
      padding: 15px 0;
      font-size: 15px;
      font-weight: bold;
    }
    .hdzq {
      font-weight: bold;
      font-size: 15px;
      color: #3a78f9;
      margin-top: 10px;
    }
    .hdcw {
      font-weight: bold;
      font-size: 15px;
      color: #fe4a4a;
      margin-top: 10px;
    }
    .analysis,
    .answer {
      font-size: 15px;
    }
  }
  .el-radio-group,
  .el-checkbox-group {
    .el-checkbox {
      // display: flex;
      // align-items: center;
    }
    .el-radio,
    .el-radio__input,
    .el-checkbox,
    .el-checkbox__input {
      white-space: normal !important;
      line-height: 175%;
    }
    p {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
